const menus = [
    { val: 10, label: '用户管理' },
    { val: 20, label: '计划单' },
    { val: 30, label: '下卡管理' },
    { val: 40, label: '产品日志' },
    { val: 50, label: '产品管理' },
]


const permissions = ['查看权限', '增加权限', '修改权限', '删除权限'];

export { menus, permissions };