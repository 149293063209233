import Vue from 'vue'
import App from './App.vue'
import * as qiniu from 'qiniu-js';

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

Vue.prototype.$host = 'https://tmp.dejawooo.com'
// Vue.prototype.$host = process.env.NODE_ENV === 'development' ? '' : 'https://www.dejawooo.cn';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
Vue.prototype.$qiniu = qiniu;

import axios from 'axios'


axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  // 获取token, 并添加到 headers 请求头中
  const token = localStorage.getItem('admin_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  let host = localStorage.getItem('host') || Vue.prototype.$host;
  if (host == 'intl') host = 'https://tmp.dejawooo.com'
  if (host == 'cn') host = 'https://www.dejawooo.cn'
  config.url = host + config.url;
  return config;
});

axios.interceptors.response.use(null, err => {
  // 没有登录或令牌过期
  if (err.response.status === 500 && err.response.data.message == "Unauthenticated.") {
    // 跳转的登录页
    Vue.prototype.$message("登陆已过期，请重新登陆!");
    router.push('/login');
  } else {
    Vue.prototype.$message(err.response.data.message)
  }
  return Promise.reject(err);
});

Vue.prototype.$http = axios


import { menus, permissions } from "./data";
Vue.prototype.$menus = menus
Vue.prototype.$permissions = permissions
import moment from 'moment'
Vue.prototype.$moment = moment

import router from './router';

Vue.prototype.$check = (code) => {
  if (localStorage.getItem('role') == 0) return true;
  let views = JSON.parse(localStorage.getItem('views'));
  return views.indexOf(code) == -1 ? false : true;
}


Vue.prototype.$isAdmin = () => {
  if (localStorage.getItem('role') == 0) return true;
  return false;
}



// 路由守卫
router.beforeEach((to, from, next) => {
  // 判断要进入的路由是否需要认证
  if (to.meta.auth) {
    // 通过token令牌机制判断是否已经登录
    const token = localStorage.getItem('token');
    if (token && (to.meta.code == undefined || Vue.$check(to.meta.code))) {
      next(); // 如果登录则放行，进入路由
    } else {
      // 跳转，并携带重定向地址
      next({
        path: '/login',
        query: {
          redirect: to.path
        }
      });
    }
  } else {
    // 不需要验证的模块，直接放行
    document.title = 'dejaWOOO'
    next();
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
