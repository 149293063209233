import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter); //全局方法 Vue.use() 使用插件，需要在你调用 new Vue() 启动应用之前完成：


export default new VueRouter({
    saveScrollPosition: true,
    routes: [{
        name: 'index',
        path: '/',
        component: resolve => void (require(['@/components/common/Home.vue'], resolve)),
        meta: {
            auth: true,
            title:'dejaWOOO'
        },
        children: [{
            path: '/',
            redirect: '/dashboard'
        }, {
            name: 'dashboard',
            path: '/dashboard',
            component: resolve => void (require(['@/components/Dashboard.vue'], resolve)),
            meta: {
                code: 10,
                title: 'dejaWOOO'
            },
        },
        { 
            name: 'products',
            path: 'products',
            component: resolve => void (require(['@/components/products/Index.vue'], resolve)),
            children: [
                {
                    name: 'products_list',
                    path: 'list/:tag',
                    component: resolve => void (require(['@/components/products/List.vue'], resolve)),
                },
            ]
        },
        {
            name: 'stores',
            path: 'stores',
            component: resolve => void (require(['@/components/stores/Index.vue'], resolve)),
            children: [
                {
                    name: 'stores',
                    path: 'list',
                    component: resolve => void (require(['@/components/stores/List.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: 'Stores'
                    },
                },
            ]
        },
        {
            name: 'theme',
            path: 'theme',
            component: resolve => void (require(['@/components/theme/Index.vue'], resolve)),
            children: [
                {
                    name: 'theme_list',
                    path: 'list',
                    component: resolve => void (require(['@/components/theme/List.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: '商家管理'
                    },
                },
            ]
        },
        {
            name: 'express',
            path: 'express',
            component: resolve => void (require(['@/components/express/Index.vue'], resolve)),
            children: [
                {
                    name: 'setting',
                    path: 'setting',
                    component: resolve => void (require(['@/components/express/Setting.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: '快递设置'
                    },
                },
                {
                    name: 'list',
                    path: 'list',
                    component: resolve => void (require(['@/components/express/List.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: '物流列表'
                    },
                }
            ]
        },
        {
            name: 'order',
            path: 'order',
            component: resolve => void (require(['@/components/order/Index.vue'], resolve)),
            children: [
                {
                    name: 'list',
                    path: 'list',
                    component: resolve => void (require(['@/components/order/List.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: '订单列表'
                    },
                },
                {
                    name: 'services',
                    path: 'services',
                    component: resolve => void (require(['@/components/order/Services.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: '售后管理'
                    },
                },

                {
                    name: 'comments',
                    path: 'comments',
                    component: resolve => void (require(['@/components/order/Comments.vue'], resolve)),
                    meta: {
                        code: 30,
                        title: '下卡管理'
                    },
                }

            ]
        },
        {
            name: 'setting',
            path: '/setting',
            component: resolve => void (require(['@/components/Setting.vue'], resolve)),
            meta: {
                code: 40,
                title: '产品日志'
            },
        }, {
            name: 'user',
            path: '/user',
            component: resolve => void (require(['@/components/User.vue'], resolve)),
            meta: {
                code: 10,
                title: '用户管理'
            },
        }, {
            name: 'size',
            path: '/size',
            component: resolve => void (require(['@/components/Size.vue'], resolve)),
            meta: {
                code: 10,
                title: '尺寸对照'
            },
        },{
            name: 'notice',
            path: '/notice',
            component: resolve => void (require(['@/components/Notice.vue'], resolve)),
            meta: {
                code: 10,
                title: '用户管理'
            },
        }, {
            name: 'api',
            path: '/api',
            component: resolve => void (require(['@/components/Api.vue'], resolve)),
            meta: {
                code: 10,
                title: '产品管理'
            },
        }, {
                name: 'Stats',
                path: 'stats',
                component: resolve => void (require(['@/components/stats/Index.vue'], resolve)),
                children: [
                    {
                        name: 'User',
                        path: 'user',
                        component: resolve => void (require(['@/components/stats/User.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '用户管理'
                        },
                    },
                    {
                        name: 'Address',
                        path: 'address',
                        component: resolve => void (require(['@/components/stats/Address.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '用户收货地址'
                        },
                    },
                    {
                        name: 'Order',
                        path: 'order',
                        component: resolve => void (require(['@/components/stats/Order.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '订单统计'
                        },
                    },
                    {
                        name: 'Echarts',
                        path: 'echarts',
                        component: resolve => void (require(['@/components/stats/Echarts.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '图表统计'
                        },
                    },
                ]
        },{
                name: 'Data',
                path: 'data',
                component: resolve => void (require(['@/components/data/Index.vue'], resolve)),
                children: [
                    {
                        name: 'Product',
                        path: 'product',
                        component: resolve => void (require(['@/components/data/Product.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '商品浏览数据'
                        },
                    },
                    {
                        name: 'Button',
                        path: 'button',
                        component: resolve => void (require(['@/components/data/Button.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '按钮数据'
                        },
                    },
                    {
                        name: 'Search',
                        path: 'search',
                        component: resolve => void (require(['@/components/data/Search.vue'], resolve)),
                        meta: {
                            code: 30,
                            title: '搜索数据'
                        },
                    },
                ]
            }]
    },

    {
        name: 'login',
        path: '/login',
        component: resolve => void (require(['@/components/Login.vue'], resolve)),
        meta: {
            code: 10,
            title: '登陆'
        },
    },
    ]
})
